import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import notFoundGIF from '../../content/assets/404.gif'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <div style={{textAlign: `center`}}>
          <SEO title="404: Not Found" />
          <h1>Not Found</h1>
          <p>Hmm... we couldn't find that page.</p>
          <img style={{textAlign: `center`}} src={notFoundGIF} alt="Otter dancing with a fish" />
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
